import { useTranslation } from "react-i18next";
import { Dropdown, IconButton, ListItemDecorator, Menu, MenuButton, MenuItem, Modal } from "@mui/joy";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import * as React from "react";
import { useGetSelfQuery } from "../app/services/userApi";
import { ProfileDialogLoading } from "./Loading";
import DeviceDialog from "./DeviceDialog";

const ProfileDialog = React.lazy(() => import("./ProfileDialog"));

const NavigationMenu = ({ screen }: { screen?: "mobile" | "desktop" }) => {
    const { t } = useTranslation("navigation");
    const [openProfile, setOpenProfile] = React.useState<boolean>(false);
    const [openDevice, setOpenDevice] = React.useState<boolean>(false);
    const { isLoading, error, data } = useGetSelfQuery()
    return (
        <>
            <Dropdown>
                <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{
                        root: {
                            variant: 'plain', color: 'neutral',
                        }
                    }}
                >
                    <SettingsIcon />
                </MenuButton>
                <Menu>
                    <MenuItem variant="plain" color="neutral" onClick={() => setOpenProfile(true)}>
                        <ListItemDecorator><AccountCircleIcon /></ListItemDecorator>
                        {t("menuitem__profile")}
                    </MenuItem>
                    <MenuItem variant="plain" color="neutral" onClick={() => setOpenDevice(true)}>
                        <ListItemDecorator><DevicesOtherIcon /></ListItemDecorator>
                        {t("menuitem__device")}
                    </MenuItem>
                </Menu>
            </Dropdown>
            <Modal
                aria-labelledby="close-modal-title"
                open={openProfile || (!isLoading && data !== undefined && data !== null && (!data.name
                    || !data.birthdate
                    || !data.gender
                    || !data.height
                    || !data.weight))}
                onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpenProfile(false);
                }}
            >
                <React.Suspense fallback={<ProfileDialogLoading screen={screen} />}>
                    <ProfileDialog screen={screen} />
                </React.Suspense>
            </Modal>
            <Modal
                aria-labelledby="close-modal-title"
                open={openDevice}
                onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                    setOpenDevice(false);
                }}>
                <React.Suspense fallback={<ProfileDialogLoading screen={screen} />}>
                    <DeviceDialog screen={screen} />
                </React.Suspense>
            </Modal >

        </>
    );
};

export default NavigationMenu;
