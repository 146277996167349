import {createApi} from '@reduxjs/toolkit/query/react'
import baseQuery from "./platformBaseQuery";


export type NoteView = {
    id: string,
    date: Date,
    comment: string
};

export type NoteListView = Array<NoteView>;

export type CreateNote = {
    date: Date,
    comment: string
}

export type EditNote = {
    date: Date,
    comment: string
}

// noinspection TypeScriptValidateTypes
export const noteApi = createApi({
    reducerPath: 'noteApi',
    baseQuery,
    endpoints: (builder) => ({
        getNoteList: builder.query<NoteListView, { dateFrom: Pick<NoteView, "date">; dateTo: Pick<NoteView, "date">, }>({
            query: ({dateFrom, dateTo}) => ({
                url: `/api/v1/logbook/notes`,
                params: {dateFrom, dateTo},
            })
        }),
        getNote: builder.query<NoteView, Pick<NoteView, "id">>({
            query: (noteId: Pick<NoteView, "id">) => ({
                url: `/api/v1/logbook/notes/${noteId}`,
            })
        }),
        createNote: builder.mutation<NoteView, CreateNote>({
            query: (createNote: CreateNote) => ({
                url: `/api/v1/logbook/notes`,
                method: 'POST',
                body: createNote
            })
        }),
        updateNote: builder.mutation<NoteView, { noteId: Pick<NoteView, "id">, editNote: Partial<EditNote> }>({
            query: ({noteId, editNote}) => ({
                url: `/api/v1/logbook/notes/${noteId}`,
                method: 'PUT',
                body: editNote
            })
        }),
        deleteNote: builder.mutation<void, Pick<NoteView, "id">>({
            query: (noteId: Pick<NoteView, "id">) => ({
                url: `/api/v1/logbook/notes/${noteId}`,
                method: 'DELETE'
            })
        })
    })
});

export const {
    useGetNoteListQuery,
    useCreateNoteMutation,
    useUpdateNoteMutation,
    useDeleteNoteMutation,
} = noteApi
