import React from "react";

import DayViewLayout from "../../../components/AgendaDayViewLayout";
import AgendaDayViewHeader from "../../../components/AgendaDayViewHeader";
import AgendaDayViewBody from "../../../components/AgendaDayViewBody";
import AgendaDayViewFooter from "../../../components/AgendaDayViewFooter";

const DayViewAgendaManager: React.FC = () => {
    return (
        <DayViewLayout.Root>
            <DayViewLayout.Header>
                <AgendaDayViewHeader/>
            </DayViewLayout.Header>
            <DayViewLayout.Main>
                <AgendaDayViewBody/>
            </DayViewLayout.Main>
            <DayViewLayout.Footer>
                <AgendaDayViewFooter/>
            </DayViewLayout.Footer>
        </DayViewLayout.Root>
    )
}

export default DayViewAgendaManager;