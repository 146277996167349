import { createApi } from "@reduxjs/toolkit/query/react"
import baseQuery, {RedirectDTO } from "../platformBaseQuery";

export type GarminTokenStatus = "active" | "invalid" | "revoked" | "refreshing"

export type GarminUserInfo = {
    garminUserId: string,
    tokenStatus: GarminTokenStatus,
};

export type GarminConnectLogin = {
    userId: string,
    garminUserId?: string
}


// noinspection TypeScriptValidateTypes
export const garminUserApi = createApi({
    reducerPath: "garminUserApi",
    baseQuery,
    tagTypes: ["GarminUser"],
    endpoints: (builder) => ({
        getAccounts: builder.query<GarminUserInfo[], void>({
            query: () => ({
                url: `/api/v1/garmin/user/accounts`,
            }),
            providesTags: (result, error, arg) => ["GarminUser"],
        }),
    })
});

export const {
    useGetAccountsQuery,
    useLazyGetAccountsQuery
} = garminUserApi


// noinspection TypeScriptValidateTypes
export const garminAuthApi = createApi({
    reducerPath: "garminAuthApi",
    baseQuery: baseQuery,
    tagTypes: ["GarminUser"],
    endpoints: (builder) => ({
        login: builder.mutation<RedirectDTO, Partial<GarminConnectLogin>>({
            query: (body) => ({
                url: `/api/v1/garmin/auth/login`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: ["GarminUser"],
        }),
    })
});

export const {
    useLoginMutation
} = garminAuthApi
