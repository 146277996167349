import {configureStore} from "@reduxjs/toolkit"
import {eventApi} from "./services/eventApi"
import {planApi} from "./services/planApi"
import {weeklyReportApi} from "./services/weeklyReportApi"
import {workoutApi} from "./services/workoutApi"
import {noteApi} from "./services/noteApi"
import {userApi} from "./services/userApi"
import dayAgenda from "../features/agenda/day/dayAgendaSlice"
import weekAgenda from "../features/agenda/week/weekAgendaSlice"
import user from "../features/user/userSlice"
import { garminAuthApi, garminUserApi } from "./services/devices/garminApi"

export const store = configureStore({
    reducer: {
        [eventApi.reducerPath]: eventApi.reducer,
        [planApi.reducerPath]: planApi.reducer,
        [weeklyReportApi.reducerPath]: weeklyReportApi.reducer,
        [workoutApi.reducerPath]: workoutApi.reducer,
        [noteApi.reducerPath]: noteApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [garminAuthApi.reducerPath]: garminAuthApi.reducer,
        [garminUserApi.reducerPath]: garminUserApi.reducer,
        dayAgenda,
        weekAgenda,
        user
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(eventApi.middleware)
            .concat(planApi.middleware)
            .concat(weeklyReportApi.middleware)
            .concat(workoutApi.middleware)
            .concat(noteApi.middleware)
            .concat(userApi.middleware)
            .concat(garminAuthApi.middleware)
            .concat(garminUserApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch


export default store