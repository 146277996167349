import {createApi} from "@reduxjs/toolkit/query/react"
import baseQuery from "./platformBaseQuery";


export type UserView = {
    id: string,
    locale: string,
    name: string,
    birthdate: string,
    gender: "MALE" | "FEMALE",
    height: number,
    weight: number,
    cyclingFtp: number,
    cyclingLthr: number,
    runningPace: number,
    runningLthr: number,
    swimmingPace: number
};

export type EditUser = {
    locale: string,
    name: string,
    birthdate: string,
    gender: "MALE" | "FEMALE",
    height: number,
    weight: number,
    cyclingFtp: number,
    cyclingLthr: number,
    runningPace: number,
    runningLthr: number,
    swimmingPace: number
};

// noinspection TypeScriptValidateTypes
export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery,
    tagTypes: ["User", "Workout"],
    endpoints: (builder) => ({
        getSelf: builder.query<UserView, void>({
            query: () => ({
                url: `/api/v1/user/self`,
            }),
            providesTags: (result, error, arg) => ["User"],
        }),
        updateSelf: builder.mutation<UserView, { editUser: Partial<EditUser> }>({
            query: ({editUser}) => ({
                url: `/api/v1/user/self`,
                method: "PUT",
                body: editUser
            }),
            invalidatesTags: ["User", "Workout"],
        }),
    })
});

export const {
    useGetSelfQuery,
    useLazyGetSelfQuery,
    useUpdateSelfMutation,
} = userApi
