import { Modal, ModalOverflow, ModalDialog, Tabs, ModalClose, Typography, Divider, TabList, Tab, ListItemDecorator, Box, TabPanel } from '@mui/joy';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { useTranslation } from 'react-i18next';
import {ReactComponent as LogoGarmin} from './devices/icons/GarminConnect_ICN_512.svg';
import GarminScreen from './devices/GarminScreen';




const DeviceDialog = ({ screen }: { screen?: "mobile" | "desktop" }) => {
    const { t } = useTranslation("device");
    return (
        <ModalOverflow>
            <ModalDialog
                layout={screen === "mobile" ? "fullscreen" : "center"}
                color="neutral"
                size="lg"
                variant="outlined"
                role="alertdialog"
                aria-labelledby="alert-dialog-modal-title"
                aria-describedby="alert-dialog-modal-description"
                sx={{ width: "50vw" }}
            >
                <ModalClose />
                < Typography
                    id="alert-dialog-modal-title"
                    component="h2"
                    startDecorator={< DevicesOtherIcon />}
                >
                    {t("dialog_title__devices_label")}
                </Typography>
                < Divider />
                <Tabs>
                    <TabList
                        sx={{ overflow: 'scroll' }}
                    >
                        <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>
                            <ListItemDecorator sx={{ mr: 0.5  }}>
                               <LogoGarmin/>
                            </ListItemDecorator>
                            <Box sx={{ display: { xs: "none", md: "block" } }}>{ t("dialog_tab__garmin")}</Box>
                        </Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <GarminScreen/>
                    </TabPanel>
                </Tabs>
            </ModalDialog>
        </ModalOverflow >
    )
}

export default DeviceDialog