import {createApi} from '@reduxjs/toolkit/query/react'
import baseQuery from "./platformBaseQuery";


export type WeeklyReport = {
    activityYear: number
    activityWeek: number
    totalPlannedDuration: number
    totalFactDuration: number
    swimmingPlannedDuration: number
    swimmingFactDuration: number
    cyclingPlannedDuration: number
    cyclingFactDuration: number
    runningPlannedDuration: number
    runningFactDuration: number
};

export type WeeklyReportView = { [year: number]: { [week: number]: WeeklyReport } };

export const weeklyReportApi = createApi({
    reducerPath: 'weeklyReportApi',
    baseQuery,
    endpoints: (builder) => ({
        getWeeklyReport: builder.query<WeeklyReportView, { year: number }>({
            query: ({year}) => ({
                url: `/api/v1/logbook/analytics?year=${year}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                const groupByYearAndWeek: WeeklyReportView = {}
                response.forEach((report: WeeklyReport) => {
                    const {activityYear, activityWeek} = report
                    groupByYearAndWeek[activityYear] ||= {}
                    groupByYearAndWeek[activityYear][activityWeek] = report
                })
                return groupByYearAndWeek
            },
        }),
    })
});

export const {
    useGetWeeklyReportQuery
} = weeklyReportApi
