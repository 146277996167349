import * as React from "react"
import {useState} from "react"
import {Box, Button, Modal} from "@mui/joy";
import PlanBuilderDialog from "./PlanBuilderDialog";
import {PlanBuilderDialogLoading} from "./Loading";
import {useTranslation} from "react-i18next";
import NavigationMenu from "./NavigationMenu";

export const AgendaDayViewFooter = () => {
    const {t} = useTranslation("navigation");
    const [openPlanBuilder, setOpenPlanBuilder] = useState<boolean>(false);
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between"
        }}>
            <Box>
                <Button onClick={() => setOpenPlanBuilder(true)}>{t("button__create_plan")}</Button>
                <Modal
                    aria-labelledby="close-modal-title"
                    open={openPlanBuilder}
                    onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                        setOpenPlanBuilder(false);
                    }}
                >
                    <React.Suspense fallback={<PlanBuilderDialogLoading/>}>
                        <PlanBuilderDialog close={() => setOpenPlanBuilder(false)}/>
                    </React.Suspense>
                </Modal>
            </Box>
            <Box>
                <NavigationMenu screen="mobile"/>
            </Box>
        </Box>
    )
}

export default AgendaDayViewFooter
