import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit"
import {add, eachDayOfInterval, endOfWeek, formatISO, Interval, parseISO, startOfWeek, sub} from "date-fns"
import {RootState} from "../../../app/store"


export type WeekAgendaState = {
    today: string
    start: string
    end: string
    dayRange: Array<string>
}

const slice = createSlice({
    name: "weekAgendaSlice",
    initialState: {
        today: formatISO(new Date()),
        start: formatISO(startOfWeek(new Date())),
        end: formatISO(endOfWeek(new Date())),
        dayRange: eachDayOfInterval({
            start: startOfWeek(new Date()),
            end: endOfWeek(new Date())
        } as Interval).map(it => formatISO(it)),
    } as WeekAgendaState,
    reducers: {
        reinit: (state: Draft<WeekAgendaState>) => {
            state.start = formatISO(startOfWeek(new Date()));
            state.end = formatISO(endOfWeek(new Date()));
            state.dayRange = eachDayOfInterval({
                start: startOfWeek(new Date()),
                end: endOfWeek(new Date())
            } as Interval).map(it => formatISO(it));
        },
        togglePrevWeek: (state: Draft<WeekAgendaState>) => {
            const start = startOfWeek(sub(parseISO(state.start), {days: 1}));
            const end = endOfWeek(sub(parseISO(state.start), {days: 1}));
            const dayRange = eachDayOfInterval({
                start: startOfWeek(start),
                end: endOfWeek(end)
            } as Interval);
            state.start = formatISO(start);
            state.end = formatISO(end);
            state.dayRange = dayRange.map(it => formatISO(it));
        },
        toggleNextWeek: (state: Draft<WeekAgendaState>) => {
            const start = startOfWeek(add(parseISO(state.end), {days: 1}));
            const end = endOfWeek(add(parseISO(state.end), {days: 1}));
            const dayRange = eachDayOfInterval({
                start: startOfWeek(start),
                end: endOfWeek(end)
            } as Interval);
            state.start = formatISO(start);
            state.end = formatISO(end);
            state.dayRange = dayRange.map(it => formatISO(it));
        },
        toggleToday: (state: Draft<WeekAgendaState>) => {
            state.start = formatISO(startOfWeek(new Date()));
            state.end = formatISO(endOfWeek(new Date()));
            state.dayRange = eachDayOfInterval({
                start: startOfWeek(new Date()),
                end: endOfWeek(new Date())
            } as Interval).map(it => formatISO(it));
        },
        setDate: (state: Draft<WeekAgendaState>, payload: PayloadAction<string>) => {
            const newDate = parseISO(payload.payload)
            state.start = formatISO(startOfWeek(newDate));
            state.end = formatISO(endOfWeek(newDate));
            state.dayRange = eachDayOfInterval({
                start: startOfWeek(newDate),
                end: endOfWeek(newDate)
            } as Interval).map(it => formatISO(it));
        }
    }
});

export const {
    reinit,
    togglePrevWeek,
    toggleNextWeek,
    toggleToday,
    setDate
} = slice.actions;


export const selectToday = (state: RootState) => state.weekAgenda.today;
export const selectStart = (state: RootState) => state.weekAgenda.start;
export const selectEnd = (state: RootState) => state.weekAgenda.end;
export const selectDayRange = (state: RootState) => state.weekAgenda.dayRange;
export const selectWorkoutsByDate = (state: RootState, date: Date) => [];

export default slice.reducer
