import * as React from "react";
import {FormControl, FormLabel, Input, InputProps} from "@mui/joy";
import {unstable_useDateField as useDateField, UseDateFieldProps} from "@mui/x-date-pickers/DateField";
import {BaseSingleInputFieldProps, DateValidationError, FieldSection} from "@mui/x-date-pickers";
import {DatePicker, DatePickerProps} from "@mui/x-date-pickers/DatePicker";


interface JoyFieldProps extends InputProps {
    label?: React.ReactNode;
    InputProps?: {
        ref?: React.Ref<any>;
        endAdornment?: React.ReactNode;
        startAdornment?: React.ReactNode;
    };
    formControlSx?: InputProps['sx'];
}

type JoyFieldComponent = ((
    props: JoyFieldProps & React.RefAttributes<HTMLDivElement>,
) => React.JSX.Element) & { propTypes?: any };

const JoyField = React.forwardRef(
    (props: JoyFieldProps, inputRef: React.Ref<HTMLInputElement>) => {
        const {
            disabled,
            id,
            label,
            InputProps: {ref: containerRef, startAdornment, endAdornment} = {},
            formControlSx,
            ...other
        } = props;

        return (
            <FormControl
                disabled={disabled}
                id={id}
                sx={[
                    // ...(Array.isArray(formControlSx) ? formControlSx : [formControlSx]),
                ]}
                ref={containerRef}
            >
                <FormLabel>{label}</FormLabel>
                <Input required
                       size="lg"
                       disabled={disabled}
                       slotProps={{input: {ref: inputRef}}}
                       startDecorator={startAdornment}
                       endDecorator={endAdornment}
                       {...other}
                />
            </FormControl>
        );
    },
) as JoyFieldComponent;

interface JoyDateFieldProps
    extends UseDateFieldProps<Date>,
        BaseSingleInputFieldProps<Date | null, Date, FieldSection, DateValidationError> {
}

function JoyDateField(props: JoyDateFieldProps) {
    const {inputRef: externalInputRef, slots, slotProps, ...textFieldProps} = props;

    const response = useDateField<Date, typeof textFieldProps>({
        props: textFieldProps,
        inputRef: externalInputRef,
    });

    return <JoyField {...response} />;
}


function JoyDatePicker(props: DatePickerProps<Date>) {
    return (
        <DatePicker
            {...props}
            slots={{field: JoyDateField, ...props.slots}}
            slotProps={{
                field: {
                    formControlSx: {},
                } as any,
            }}
        />
    );
}

export default JoyDatePicker