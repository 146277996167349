import {createApi} from "@reduxjs/toolkit/query/react"
import baseQuery from "./platformBaseQuery";

export type EventView = {
    id: string,
    title: string,
    dateFrom: string,
    dateTo: string
};

export type EventListView = {};

export type CreateEvent = {
    priority: "A" | "B" | "C",
    planId: string,
    title: string,
    dateFrom: string,
    dateTo: string,
    includeTest: boolean,
    calendarSlots: Array<{
        sportType: "SWIMMING" | "CYCLING" | "RUNNING" | "GYM",
        dow: number
    }>
};

// noinspection TypeScriptValidateTypes
export const eventApi = createApi({
    reducerPath: "eventApi",
    baseQuery,
    tagTypes: ["Workout"],
    endpoints: (builder) => ({
        getEventList: builder.query<EventListView, { dateFrom: Pick<EventView, "dateFrom">; dateTo: Pick<EventView, "dateTo"> }>({
            query: ({dateFrom, dateTo}) => ({
                url: `/api/v1/logbook/events`,
                params: {dateFrom, dateTo},
            })
        }),
        createEvent: builder.mutation<EventView, CreateEvent>({
            query: (createEvent: CreateEvent) => ({
                url: `/api/v1/logbook/events`,
                method: "POST",
                body: createEvent
            }),
            invalidatesTags: ["Workout"],
        }),
        deleteEvent: builder.mutation<void, Pick<EventView, "id">>({
            query: (eventId: Pick<EventView, "id">) => ({
                url: `/api/v1/logbook/events/${eventId}`,
                method: "DELETE"
            }),
            invalidatesTags: ["Workout"],
        })
    })
});

export const {
    useGetEventListQuery,
    useCreateEventMutation,
    useDeleteEventMutation,
} = eventApi
