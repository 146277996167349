import * as React from "react";
import {Box, Grid, IconButton, Typography} from "@mui/joy";
import PrevIcon from "@mui/icons-material/ChevronLeft";
import NextIcon from "@mui/icons-material/ChevronRight";
import {format, formatISO, parseISO} from "date-fns";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    selectDayRange,
    selectToday,
    setDate,
    toggleNextWeek,
    togglePrevWeek
} from "../features/agenda/day/dayAgendaSlice";

const AgendaDayViewHeader = () => {
    const dispatch = useAppDispatch();
    // Mon, Tue, Wed, ..., Sun
    const formatDOW = (date: Date) => format(date, "EEEEEE");
    // 1, 2, ..., 31
    const formatDOM = (date: Date) => format(date, "d");
    // get range
    const dayRange = useAppSelector(state => selectDayRange(state)).map(it => parseISO(it));
    const today = parseISO(useAppSelector(state => selectToday(state)));
    return (
        <>
            <IconButton aria-label="Prev week" variant="plain" color="neutral">
                <PrevIcon onClick={() => dispatch(togglePrevWeek())}/>
            </IconButton>
            <Grid container columns={7} spacing={1.5} pt={0.5} pb={2} mb={1.5} sx={{width: '100%'}}>
                {dayRange.map(date => (
                    <Grid xs key={date.toISOString()}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Typography
                                textAlign="center"
                                textColor="neutral.500"
                                fontWeight={700}
                                sx={{
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    // letterSpacing: '.1rem',
                                }}
                            >
                                {formatDOW(date)}
                            </Typography>
                            <Box
                                sx={{
                                    cursor: "pointer",
                                    borderRadius: '50px',
                                    width: '2rem',
                                    height: '2rem',
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                    textColor: "neutral.500",
                                    textTransform: 'uppercase',
                                    // fontWeight: 700,
                                    // fontSize: 'xl',
                                    letterSpacing: '.1rem',
                                    backgroundColor: format(date, "yyyy-LL-dd") === format(today, "yyyy-LL-dd") ? "focusVisible" : "none",
                                    // p: 0.5
                                }}
                                onClick={() => dispatch(setDate(formatISO(date)))}
                            >
                                {formatDOM(date)}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <IconButton aria-label="Next week" variant="plain" color="neutral">
                <NextIcon onClick={() => dispatch(toggleNextWeek())}/>
            </IconButton>
        </>
    );
};

export default AgendaDayViewHeader