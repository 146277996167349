import {extendTheme as extendJoyTheme} from '@mui/joy/styles';
import {experimental_extendTheme as extendMaterialTheme,} from '@mui/material/styles';

const joyTheme = extendJoyTheme({
    fontFamily: {
        display: "'Inter', var(--joy-fontFamily-fallback)",
        body: "'Inter', var(--joy-fontFamily-fallback)",
    },
    // transitions: {
    //     duration: {
    //         enteringScreen: 10,
    //         leavingScreen: 10,
    //     }
    // },
    // typography: {
    //     pxToRem: fun(px) => {
    //         return 1;
    //     }
    // }
});

const materialTheme = extendMaterialTheme({});

// delete materialTheme.colorSchemes.light;
// delete joyTheme.colorSchemes.light;

export default {joyTheme, materialTheme}
