import {createApi} from "@reduxjs/toolkit/query/react"
import {format, parseISO} from "date-fns"
import baseQuery from "./platformBaseQuery";

const DATE_FORMAT = "yyyyLLdd"

export type WorkoutView = {
    id: string,
    sportType: "SWIMMING" | "CYCLING" | "RUNNING" | "GYM",
    date: Date,
    sort: number,
    completed: boolean,
    comment: string,
    feeling: number,
    perceivedExertion: number,
    duration: number,
    factDuration: number,
    title: string,
    description: string,
    shortDescription: string,
    steps: Array<{
        groupId: string,
        stepType: "WARMUP" | "ACTIVE" | "REST" | "COOLDOWN",
        duration: number,
        rpeLow: number,
        rpeHigh: number,
        hrLow: number,
        hrHigh: number,
        powerLow: number,
        powerHigh: number,
        paceLow: number,
        paceHigh: number,
        cadenceLow: number,
        cadenceHigh: number,
        passiveRecovery: boolean,
        groupSeries: number,
        groupRepeats: number,
        groupDuration: number,
        numSeries: number,
        numRepeat: number,
        sort: number,
        comment: string
    }>,
    alternatives: Array<WorkoutView>;
};

export type WorkoutListView = Array<WorkoutView>;

export type EditWorkout = {
    date: string,
    sort: number,
    completed: boolean,
    comment: string,
    feeling: number
    perceivedExertion: number
};


// noinspection TypeScriptValidateTypes
export const workoutApi = createApi({
    reducerPath: "workoutApi",
    baseQuery,
    tagTypes: ["Workout"],
    endpoints: (builder) => ({
        getWorkoutList: builder.query<WorkoutListView, { dateFrom: string; dateTo: string }>({
            query: ({dateFrom, dateTo}) => ({
                url: `/api/v1/logbook/workouts`,
                params: {
                    date_from: format(parseISO(dateFrom), DATE_FORMAT),
                    date_to: format(parseISO(dateTo), DATE_FORMAT)
                },
            }),
            providesTags: (result, error, arg) =>
                result
                    ? [...result.map(({id}) => ({type: "Workout" as const, id})), "Workout"]
                    : ["Workout"],
        }),
        getWorkout: builder.query<WorkoutView, string>({
            query: (workoutId) => ({
                url: `/api/v1/logbook/workouts/${workoutId}`,
            }),
        }),
        updateWorkout: builder.mutation<WorkoutView, { workoutId: string, editWorkout: Partial<EditWorkout> }>({
            query: ({workoutId, editWorkout}) => ({
                url: `/api/v1/logbook/workouts/${workoutId}`,
                method: "PUT",
                body: editWorkout
            }),
            invalidatesTags: ["Workout"],
        })
    })
});

export const {
    useGetWorkoutListQuery,
    useUpdateWorkoutMutation,
} = workoutApi
