import React, {lazy, Suspense} from "react"
import {Grid, List, Typography} from "@mui/joy"
import {format, formatISO, parseISO} from "date-fns"
import {useAppSelector} from "../app/hooks"
import {selectDayRange, selectEnd, selectStart} from "../features/agenda/week/weekAgendaSlice"
import {useGetWorkoutListQuery} from "../app/services/workoutApi"
import {AgendaWeekViewBodyLoading, WorkoutCardLoading} from "./Loading"
import {useTranslation} from "react-i18next";

const WorkoutCard = lazy(() => import('./WorkoutCard'));

const AgendaDate = ({workouts}: { workouts: Array<any> }) => {
    const {t} = useTranslation("navigation");
    if (workouts.length === 0) {
        return (
            <Grid xs>
                <Typography
                    textAlign="center"
                    textColor="neutral.500"
                    fontWeight={700}
                    mt={2}
                    sx={{
                        fontSize: "10px",
                        textTransform: "uppercase",
                    }}>
                    {t("cal__rest_day")}
                </Typography>
            </Grid>
        );
    }
    return (
        <Grid xs>
            <List aria-labelledby="workouts">
                {workouts.map(workout => (
                    <Suspense fallback={<WorkoutCardLoading/>}>
                        <WorkoutCard key={workout.id} workout={workout}/>
                    </Suspense>
                ))}
            </List>
        </Grid>
    );
};

const AgendaWeekViewBody = () => {
    const dayRange = useAppSelector(state => selectDayRange(state)).map(it => parseISO(it));
    const start = parseISO(useAppSelector(state => selectStart(state)));
    const end = parseISO(useAppSelector(state => selectEnd(state)));
    const {data, error, isLoading} = useGetWorkoutListQuery({dateFrom: formatISO(start), dateTo: formatISO(end)});
    if (isLoading) {
        return <AgendaWeekViewBodyLoading/>
    }
    if (error) {
        return <div>error</div>
    }
    const selectWorkouts = (date: Date): any[] => {
        return (data as any[]).filter(workout => workout.date === format(date, "yyyy-LL-dd"));
    }
    return (
        <Grid container spacing={1.5} columns={7} mt={0.5} sx={{width: "100%"}}>
            {dayRange.map(date => <AgendaDate key={date.toString()} workouts={selectWorkouts(date)}/>)}
        </Grid>
    );
}

export default AgendaWeekViewBody