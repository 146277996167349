import React, {lazy, Suspense} from "react";
import {Box, List, Typography} from "@mui/joy";
import {useAppSelector} from "../app/hooks";
import {selectEnd, selectStart, selectToday} from "../features/agenda/day/dayAgendaSlice";
import Workout from "../app/types/Workout";
import {format, formatISO, parseISO} from "date-fns";
import {useGetWorkoutListQuery} from "../app/services/workoutApi";
import {AgendaDayViewBodyLoading, WorkoutCardLoading} from "./Loading";
import {useTranslation} from "react-i18next";

const WorkoutCard = lazy(() => import('./WorkoutCard'));

const AgendaDayViewBody = () => {
    const {t} = useTranslation("navigation");
    // const dayRange: Array<Date> = useAppSelector(state => selectDayRange(state));
    const start = parseISO(useAppSelector(state => selectStart(state)));
    const end = parseISO(useAppSelector(state => selectEnd(state)));
    const today = parseISO(useAppSelector(state => selectToday(state)))
    const {data, error, isLoading} = useGetWorkoutListQuery({dateFrom: formatISO(start), dateTo: formatISO(end)});
    if (isLoading) {
        return <AgendaDayViewBodyLoading/>
    }
    if (error) {
        return <div>error</div>
    }
    const workouts: Array<Workout> = (data as any[]).filter(workout => workout.date === format(today, "yyyy-LL-dd"));
    if (workouts.length === 0) {
        return (
            <Box>
                <Typography
                    textAlign="center"
                    textColor="neutral.500"
                    fontWeight={700}
                    mt={2}
                    sx={{
                        fontSize: '10px',
                        textTransform: 'uppercase',
                    }}>
                    {t("cal__rest_day")}
                </Typography>
            </Box>
        )
    }
    return (
        <List sx={{p: 2}}>
            {workouts.map(workout => (
                <Suspense fallback={<WorkoutCardLoading/>}>
                    <WorkoutCard key={workout.id} workout={workout}/>
                </Suspense>
            ))}
        </List>
    )
}

export default AgendaDayViewBody;
