import React from "react";

import WeekViewLayout from "../../../components/AgendaWeekViewLayout";
import AgendaWeekViewTopbar from "../../../components/AgendaWeekViewTopbar";
import AgendaWeekViewHeader from "../../../components/AgendaWeekViewHeader";
import AgendaWeekViewBody from "../../../components/AgendaWeekViewBody";
import AgendaWeekViewSidebar from "../../../components/AgendaWeekViewSidebar";
import {AgendaWeekViewSidebarLoading} from "../../../components/Loading";

const WeekViewAgendaManager = () => {
    return (
        <WeekViewLayout.Root>
            <WeekViewLayout.Header>
                <AgendaWeekViewTopbar/>
            </WeekViewLayout.Header>
            <WeekViewLayout.SideNav>
                <React.Suspense fallback={<AgendaWeekViewSidebarLoading/>}>
                    <AgendaWeekViewSidebar/>
                </React.Suspense>
            </WeekViewLayout.SideNav>
            <WeekViewLayout.Main>
                <AgendaWeekViewHeader/>
                <AgendaWeekViewBody/>
            </WeekViewLayout.Main>
        </WeekViewLayout.Root>
    )
}

export default WeekViewAgendaManager;