import {
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    experimental_extendTheme as materialExtendTheme,
    THEME_ID as MATERIAL_THEME_ID,
    useColorScheme as useMaterialColorScheme
} from "@mui/material/styles";
import {CssVarsProvider as JoyCssVarsProvider, useColorScheme,} from '@mui/joy/styles';
import {ReactNode, useEffect, useMemo} from "react";
import {CssBaseline, ThemeProvider} from "@mui/joy";
import SuperTokens from "supertokens-auth-react";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {setDefaultOptions} from "date-fns";
import {dateFnsOptionsOverrides} from "./config/i18nConfig";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {reinit} from "./features/agenda/week/weekAgendaSlice";
import theme from "./theme";
import i18n from "./i18n";
import "animate.css"
import {fetchUserProfile, selectLocale} from "./features/user/userSlice";


const materialTheme = materialExtendTheme();

const SyncThemeMode = ({mode}: { mode: "light" | "dark" }) => {
    const {setMode} = useColorScheme();
    const {setMode: setMaterialMode} = useMaterialColorScheme();
    useEffect(() => {
        setMode(mode);
        setMaterialMode(mode);
    }, [mode, setMode, setMaterialMode]);
    return null;
}


const LayoutBaselineProvider = ({children}: { children: ReactNode }) => {
    const dispatch = useAppDispatch();
    const lang = useAppSelector(state => selectLocale(state))
    useEffect(() => {
        dispatch(fetchUserProfile());
    }, [dispatch]);
    useMemo(() => {
        i18n.changeLanguage(lang)
        SuperTokens.changeLanguage(lang);
        setDefaultOptions(dateFnsOptionsOverrides[lang]);
        dispatch(reinit());
    }, [dispatch, lang]);
    const adapterLocale = dateFnsOptionsOverrides[lang].locale
    return (
        <MaterialCssVarsProvider theme={{[MATERIAL_THEME_ID]: materialTheme}}>
            <JoyCssVarsProvider>
                <SyncThemeMode mode="dark"/>
                <ThemeProvider theme={theme.joyTheme}>
                    <CssBaseline/>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={adapterLocale}
                    >
                        {children}
                    </LocalizationProvider>
                </ThemeProvider>
            </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
    );
};

export default LayoutBaselineProvider