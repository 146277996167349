import {enUS, ru} from 'date-fns/locale'

export type DateFnsOptions = {
    locale?: Locale
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7
}

export const dateFnsOptionsOverrides: { [locale: string]: DateFnsOptions } = {
    en: {locale: enUS} as DateFnsOptions,
    ru: {locale: ru, weekStartsOn: 1} as DateFnsOptions
}