import {format, parseISO} from "date-fns";
import {Box, Grid, Typography} from "@mui/joy";
import {useAppSelector} from "../app/hooks";
import {selectDayRange} from "../features/agenda/week/weekAgendaSlice";
import {useTranslation} from "react-i18next";

const AgendaWeekViewHeader = () => {
    const {i18n} = useTranslation();
    const lang = i18n.language
    // Mon, Tue, Wed, ..., Sun
    const formatDOW = (date: Date) => format(date, lang === "ru" ? "cccccc" : "ccc");
    // 1, 2, ..., 31
    const formatDOM = (date: Date) => format(date, "d");
    const dayRange = useAppSelector(state => selectDayRange(state)).map(it => parseISO(it));
    return (
        <>
            <Grid container spacing={1.5} columns={7} sx={{px: 1, width: '100%'}}>
                {dayRange.map(date => (
                    <Grid xs key={date.toISOString()}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <Typography
                                textAlign="center"
                                textColor="neutral.500"
                                fontWeight={700}
                                mt={2}
                                sx={{
                                    fontSize: '10px',
                                    textTransform: 'uppercase',
                                    letterSpacing: '0.1rem'
                                }}
                            >
                                {formatDOW(date)}
                            </Typography>
                            <Typography textAlign="center" fontSize="lg" fontWeight="md">
                                {formatDOM(date)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default AgendaWeekViewHeader