import SwimmingIcon from "@mui/icons-material/Pool";
import CyclingIcon from "@mui/icons-material/DirectionsBike";
import RunningIcon from "@mui/icons-material/DirectionsRun";
import GymIcon from "@mui/icons-material/FitnessCenter";

const icons = {
    "SWIMMING": SwimmingIcon,
    "CYCLING": CyclingIcon,
    "RUNNING": RunningIcon,
    "GYM": GymIcon,
}

interface WorkoutIconProps {
    sportType: "SWIMMING" | "CYCLING" | "RUNNING" | "GYM";
}

const WorkoutIcon = ({sportType}: WorkoutIconProps) => {
    const Icon = icons[sportType];
    return <Icon sx={{fontSize: "inherit"}}/>
}

export default WorkoutIcon