import { Box, Button, Card, CardContent, CircularProgress, Divider, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import Session from 'supertokens-auth-react/recipe/session';
import { useGetAccountsQuery, useLoginMutation, GarminConnectLogin, GarminUserInfo, GarminTokenStatus } from '../../app/services/devices/garminApi';
import CircleIcon from '@mui/icons-material/Circle';


const connectGarmin = async (login: any, garminUserId: string | undefined = undefined) => {
    const userId = await Session.getUserId();
    const garminConnectLogin: GarminConnectLogin = {
        userId: userId,
        garminUserId: garminUserId
    }
    const redirect = await login(garminConnectLogin)
    const location = redirect?.data?.location
    if (location) {
        window.location.href = location
    }
}


const getStatusLabel = (status: GarminTokenStatus, t: any) => {
    switch (status) {
        case 'active':
            return t("label_garmin_status_active")
        case 'invalid':
            return t("label_garmin_status_invalid")
        default:
            return t("label_garmin_status_invalid")
    }
}


const getStatusColor = (status: GarminTokenStatus) => {
    switch (status) {
        case 'active':
            return "green"
        case 'invalid':
            return "red"
        default:
            return "yellow"
    }
}


const AccountCard = ({ account }: { account: GarminUserInfo }) => {
    const { t } = useTranslation("device")
    const [login] = useLoginMutation()
    return (
        <Card key={account.garminUserId}>
            <CardContent>
                <Stack spacing={1}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            marginY: 2,
                        }}
                    >
                        ID: {account.garminUserId}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginY: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "right",
                                marginY: 2,
                            }}
                        >
                            <CircleIcon sx={{ color: getStatusColor(account.tokenStatus), marginRight: 1 }} />
                            <Typography>
                                {getStatusLabel(account.tokenStatus, t)}
                            </Typography>
                        </Box>

                        <Button onClick={() =>
                            connectGarmin(login, account.garminUserId)
                            // connectGarmin(account.garminUserId)
                        }>
                            {t("dialog_button__reconnect_garmin")}
                        </Button>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}


const GarminScreen = () => {
    const { t } = useTranslation("device");
    const { data: accounts, isLoading } = useGetAccountsQuery()
    const [login] = useLoginMutation()
    if (isLoading) return <CircularProgress />

    if (accounts === undefined || accounts?.length === 0) {
        return (
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Button
                    onClick={() =>
                        connectGarmin(login)
                        // connectGarmin()
                    }>{t("dialog_button__connect_garmin")}</Button>
            </Box>
        )
    }

    return (
        <Box>
            <Stack spacing={2}>
                <Typography level="h3">{t("header__accounts")}</Typography>
                <Stack spacing={2}>
                    {accounts?.map((account: GarminUserInfo) => (<AccountCard account={account} />))}
                </Stack>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={() =>
                            connectGarmin(login)
                            // connectGarmin()
                        }>{t("dialog_button__add_garmin_account")}</Button>
                </Box>
                <Divider />
            </Stack>
        </Box>
    )
}

export default GarminScreen
