import * as React from "react";
import {
    Box,
    Button,
    IconButton,
    Modal,
    Typography
} from "@mui/joy";
import PrevIcon from "@mui/icons-material/ChevronLeft";
import NextIcon from "@mui/icons-material/ChevronRight";
import SwimmingIcon from "@mui/icons-material/Pool";
import CyclingIcon from "@mui/icons-material/DirectionsBike";
import RunningIcon from "@mui/icons-material/DirectionsRun";
import {useTranslation} from "react-i18next";
import PlanBuilderDialog from "./PlanBuilderDialog";

import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    selectEnd,
    selectStart,
    toggleNextWeek,
    togglePrevWeek,
    toggleToday
} from "../features/agenda/week/weekAgendaSlice";
import {format, getMonth, parseISO} from "date-fns"
import {PlanBuilderDialogLoading} from "./Loading";
import NavigationMenu from "./NavigationMenu";



const formatDate = (start: Date, end: Date) => {
    if (getMonth(start) !== getMonth(end)) {
        return [format(start, "LLL"), "–", format(end, "LLL yyyy")].join(" ").replace(".", "");
    }
    return format(end, "LLLL yyyy");
}

const AgendaWeekViewTopbar = () => {
    const {t} = useTranslation("navigation");
    const [openPlanBuilder, setOpenPlanBuilder] = React.useState<boolean>(false);

    const dispatch = useAppDispatch();
    const start = parseISO(useAppSelector(state => selectStart(state)));
    const end = parseISO(useAppSelector(state => selectEnd(state)));
    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1.5,
                }}
            >
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1.5, mr: 5}}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 0.5
                    }}>
                        <SwimmingIcon/>
                        <CyclingIcon/>
                        <RunningIcon/>
                        <Typography ml={0.5} component="h1" fontWeight="xl">
                            TRS
                        </Typography>
                    </Box>

                </Box>
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1.5}}>
                    <Button aria-label="Today"
                            variant="outlined"
                            color="neutral"
                            onClick={() => dispatch(toggleToday())}
                    >
                        {t("button__today")}
                    </Button>
                    <IconButton aria-label="Prev week" variant="plain" color="neutral">
                        <PrevIcon onClick={() => dispatch(togglePrevWeek())}/>
                    </IconButton>
                    <IconButton aria-label="Next week" variant="plain" color="neutral">
                        <NextIcon onClick={() => dispatch(toggleNextWeek())}/>
                    </IconButton>
                    <Typography
                        fontSize="xl"
                        fontWeight="md"
                        sx={{
                            textTransform: "capitalize"
                        }}>
                        {formatDate(start, end)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{display: "flex", flexDirection: "row", gap: 1.5}}>
                <Button onClick={() => setOpenPlanBuilder(true)}>{t("button__create_plan")}</Button>
                <Modal
                    aria-labelledby="close-modal-title"
                    open={openPlanBuilder}
                    onClose={(_event: React.MouseEvent<HTMLButtonElement>) => {
                        setOpenPlanBuilder(false);
                    }}
                >
                    <React.Suspense fallback={<PlanBuilderDialogLoading/>}>
                        <PlanBuilderDialog close={() => setOpenPlanBuilder(false)}/>
                    </React.Suspense>
                </Modal>
                <NavigationMenu screen="desktop"/>
            </Box>
        </>
    );
};

export default AgendaWeekViewTopbar;
